import { ContentTypeEnum } from '@/enums/http-enum'

/**
 * 获取驱动列表
 */
export const getDriverListApi = () => useGet<string[]>('/link/driver/list')

/**
 * 获取驱动详情
 */
// export const getDriverDetailApi = (name: string) => useGet('/link/driver/info', { name })

export async function getDriverDetailApi(name: string) {
  const res = await useGet('/link/driver/info', { name })
  if (res.abilities && res.abilities.length > 0) {
    res.abilities.forEach((ability: any) => {
      ability.detail = JSON.parse(typeof ability.detail === 'string' ? ability.detail : '{}')
    })
  }

  return res
}

/**
 * 获得驱动实例列表
 */
export async function getDriverInstancesApi() {
  const res = await useGet('/link/driver-instance/list')
  res.forEach((instance: any) => {
    const initParam = instance.driverInstance?.initParam
    if (initParam) {
      instance.driverInstance.initParam = JSON.parse(initParam)
    }
  })

  return res
}

/**
 * 新增驱动实例
 */
export const addDriverInstanceApi = (data: any) => usePost('/deploy/driver-instance', data)

/**
 * 修改驱动实例
 */
export const updateDriverInstanceApi = (data: any) => usePut('/deploy/driver-instance', data)

/**
 * 删除驱动实例
 */
export const deleteDriverInstanceApi = (id: number) => useDelete(`/deploy/driver-instance/${id}`)

/**
 * 根据id获取驱动实例详情
 */
export const getDriverInstanceByIdApi = (id: number) => useGet(`/deploy/driver-instance/${id}`)

/**
 * 启动驱动实例
 */
export const startDriverInstanceApi = (instanceId: number) => usePost('/link/driver-instance/start', { instanceId }, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 停止驱动实例
 */
export const stopDriverInstanceApi = (instanceId: number) => usePost('/link/driver-instance/stop', { instanceId }, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })
